<template>
    <v-row no-gutters>
        <v-col cols="12">
            <RealmBar :enterpriseId="$route.params.enterpriseId" :realm="realm" class="mb-6" v-if="realm"></RealmBar>
            <AccessDeniedOverlay v-if="forbiddenError"></AccessDeniedOverlay>
            <v-row justify="center" class="py-5 px-10">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">

                    <v-card>
                        <v-toolbar color="red white--text" dark flat dense>
                        <!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
                        <v-toolbar-title>
                            Delete Client Token
                        </v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>

                            <template v-if="enterprise">
                            <p class="text-overline mb-0">Enterprise name</p>
                            <p class="mb-0 pb-0">
                                <span class="font-weight-bold">{{ enterprise.name }}</span>
                            </p>
                            </template>

                            <template v-if="clientToken">
                            <p class="text-overline mb-0 mt-4">Client Token</p>
                            <p class="mb-0 pb-0">
                                <span class="font-weight-bold">{{ clientToken.label }}<!-- .{{ clientToken.domain }} --></span>
                            </p>
                            </template>

                            <p class="text-overline mb-0 mt-4">Confirmation</p>

                            <p>Tap on the button below to delete this client token. Any client applications using this token will not be able to access the APIs.</p>
                            <p>This action is not reversible -- you can create a new client token later and update your application, but we cannot restore the deleted token.</p>

                            <v-alert type="warning" border="left" dense>
                                <template #prepend>
                                    <font-awesome-icon fixed-width :icon="['fas', 'exclamation-triangle']" class="white--text text-body-1"/>
                                </template>
                                <span class="font-weight-bold ml-2">This action is not reversible.</span>
                                <!-- <font-awesome-icon fixed-width :icon="['fas', 'info-circle']" class="white--text text-body-1 ml-2"/> -->
                            </v-alert>

                            <v-checkbox color="amber darken-2" v-model="deleteConfirmation1" label="I want to delete the client token"></v-checkbox>
                            <v-checkbox color="amber darken-2" v-model="deleteConfirmation2" label="I understand this may cause a disruption if I have not already configured my application with a new token"></v-checkbox>
                            <v-checkbox color="amber darken-2" v-model="deleteConfirmation3" label="I understand this action is not reversible"></v-checkbox>
                            <v-btn color="red" class="white--text" @click="deleteClientToken" :disabled="!isdeleteClientTokenConfirmed">Delete Client Token</v-btn>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState, mapGetters } from 'vuex';
import AccessDeniedOverlay from '@/components/AccessDeniedOverlay.vue';
import RealmBar from '@/components/cryptium_id/RealmBar.vue';

export default {
    components: {
        AccessDeniedOverlay,
        RealmBar,
    },
    data: () => ({
        realm: null,
        forbiddenError: false,
        enterprise: null,
        clientToken: null,
        error: null,
        deleteConfirmation1: false,
        deleteConfirmation2: false,
        deleteConfirmation3: false,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            user: (state) => state.user,
        }),
        ...mapGetters({
            primaryColor: 'primaryColor',
            primaryTextStyle: 'primaryTextStyle',
            primaryIconButtonStyle: 'primaryIconButtonStyle',
        }),
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        isdeleteClientTokenConfirmed() {
            return this.deleteConfirmation1 && this.deleteConfirmation2 && this.deleteConfirmation3;
        },
    },
    methods: {
        async loadEnterprise() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadEnterprise: true });
                const response = await this.$client.enterprise(this.$route.params.enterpriseId).accopunt.get();
                console.log(`enterprise/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.enterprise = response;
                } else {
                    // TODO: redirect back to enterprise list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load enterprise', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadEnterprise: false });
            }
        },
        async loadClientToken() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadClientToken: true });
                const response = await this.$client.enterprise(this.$route.params.enterpriseId).clientToken.search({ id: this.$route.query.id });
                if (Array.isArray(response.list) && response.list.length > 0) {
                    this.clientToken = response.list[0];
                } else {
                    this.$router.replace({ name: 'enterprise-search-client-token', params: { enterpriseId: this.$route.params.enterpriseId } });
                }
            } catch (err) {
                console.error('failed to load dynamic dns record', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadClientToken: false });
            }
        },
        async deleteClientToken() {
            try {
                this.$store.commit('loading', { deleteClientToken: true });
                const response = await this.$client.enterprise(this.$route.params.enterpriseId).clientToken.delete({ id: this.$route.query.id });
                if (response?.isDeleted) {
                    this.$bus.$emit('snackbar', { type: 'info', headline: 'Deleted' });
                    this.$router.push({ name: 'enterprise-search-client-token', params: { enterprise: this.$route.params.enterpriseId } });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to delete item', message: 'Please contact customer support' });
                }
            } catch (err) {
                console.error('failed to delete item', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to delete item', message: 'Please contact customer support' });
            } finally {
                this.$store.commit('loading', { deleteClientToken: false });
            }
        },
    },
    mounted() {
        this.loadEnterprise();
        if (this.$route.query.id) {
            this.loadClientToken();
        } else {
            this.$router.replace({ name: 'enterprise-search-client-token', params: { enterpriseId: this.$route.params.enterpriseId } });
        }
    },
};
</script>
